import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import TestMode from "./default/TestMode";
import Nav from "./components/Nav";
import "@identitybuilding/idb-react-ui-elements/dist/styles/Animations.css";
import "@identitybuilding/idb-react-ui-elements/dist/styles/Colors.css";
import Contact from "@identitybuilding/idb-react-contact";

const Onepage = (props) => {
  let modules = useSelector((state) => state.AdminReducer.modules);
  let lang = useSelector((state) => state.AdminReducer.lang);
  let captcha_key = useSelector((state) => state.AdminReducer.captcha_key);
  let entityCopy = useSelector((state) => state.AdminReducer.data);
  let vat = useSelector((state) => state.AdminReducer.vat);
  const [persons, setPersons] = useState([]);
  const [facilities, setFacilities] = useState("");
  const [contactView, setContactView] = useState("");
  const [tab, setTab] = useState(0);
  return (
    <div>
      <Nav hover_animation="underline" />
      {modules &&
        modules.map((res, index) => (
          res.active && res.module && 
            <div className="wrapper" key={index} id={res.name.replace(/\s/g, "").replace(/\-/g, "")}>
            {res.slug === "contact" ? (
              <Contact
                CaptchaKey={captcha_key}
                title={entityCopy.options.contact.title}
                setTab={(e) => setTab(e)}
                tab={tab}
                contactView={contactView}
                setContactView={(e) => setContactView(e)}
                parts={entityCopy.menu}
                entity_id={entityCopy.establishment_number.replace(/\./g, "")}
                services={facilities}
                setServices={(e) => setFacilities(e)}
                card_theme="main"
                // id={entityCopy.vat_number
                //   .replace(/\./g, "")
                //   .replace("BE", "")}
                lang={lang}
                social_media={true}
                social_media_data={entityCopy.social_media}
                contacts={persons}
                setContacts={(e) => setPersons(e)}
                entity={{
                  zoomed: false,
                  id: vat ? vat : "",
                  type: "enterprise",
                  logos: entityCopy.logos ? entityCopy.logos : [],
                  enterprise_name: entityCopy.enterprise_name
                    ? entityCopy.enterprise_name
                    : "",
                  vat_number: vat
                    ? vat
                    : "",
                  establishment_number: entityCopy.establishment_number
                    ? entityCopy.establishment_number
                    : "",
                  juridical_form: entityCopy.juridical_form
                    ? entityCopy.juridical_form
                    : "",
                  address: entityCopy.address ? entityCopy.address : [],
                  telephone: entityCopy.telephone ? entityCopy.telephone : "",
                  cellphone: entityCopy.cellphone ? entityCopy.cellphone : [],
                  fax: entityCopy.fax ? entityCopy.fax : "",
                  facilities: [],
                  social_media: entityCopy.social_media
                    ? entityCopy.social_media
                    : [],
                  contacts: [],
                  registration_numbers: entityCopy.registration_numbers
                    ? entityCopy.registration_numbers
                    : [],
                  opening_hours_reveived: false,
                  openinghours: entityCopy.openinghours,
                  qr: `https://api.ondernemersnetwerk.be/public/business/${"BE" +
                    entityCopy.establishment_number.replace(/\./g, "")}/qr.png`,
                }}
              />
            ) : res.active && res.module ? (
              res.module
            ) : (
              ""
            )}
          </div>
        ))}
    </div>
  );
};

export default Onepage;
